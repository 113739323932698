<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <ion-toolbar class="text-center">
        <ion-buttons slot="end">
          <ion-button @click.prevent="updateRoute('messages')">
            <ion-icon :ios="mail" :md="mail"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Notifications</ion-title>
      </ion-toolbar>
    </ion-header> 
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <UserNotifications />
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="alerts" />
    </ion-footer>

  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/vue';
import { chevronBack, mail } from 'ionicons/icons';
import { defineComponent } from 'vue';
import UserNotifications from '../components/Notifications.vue'
import doAuth from '../services/auth';
import Tabs from '../components/Tabs.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Notifications',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonIcon, UserNotifications, Tabs
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const router = useRouter();

    function updateRoute(val) {
      router.push(val)
    }

    return {
      router, updateRoute, chevronBack, mail
    }
  }
});
</script>

<style scoped>
#container {
  text-align: center;
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  
  color: #8c8c8c;
  
  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>