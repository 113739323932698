<template>
	<div>
		<div v-if="notifications != null">
			<!-- If there are notifications -->
			<ion-list v-if="notifications.length > 0">

				<!-- notification -->
				<ion-item v-for="notification in notifications" :key="notification">
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-avatar slot="start" @click.prevent="viewProfile(notification.sender.username)" style="cursor:pointer!important;">
						<img :src="notification.sender.profile_photo_path">
					</ion-avatar>
					<ion-label>
						<h2 @click.prevent="viewProfile(notification.sender.username)" style="cursor:pointer!important;">
							{{notification.sender.name}}
						</h2>
						<h3>{{notification.body}}</h3>
						<!--<p>St. Louis, MO</p>-->
					</ion-label>
				</ion-item>

			</ion-list>
			<!-- No Notifications -->
			<div v-else class="text-center padding-top-md padding-bottom-md">
				You do not have any notifications
			</div>
		</div>		
	</div>
</template>

<script>
import { IonList, IonItem, IonAvatar, IonLabel } from '@ionic/vue';
import { defineComponent, ref, onMounted, computed } from 'vue';
import { useStore } from "vuex";
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent( {
	components: { IonList, IonItem, IonAvatar, IonLabel },
	name: 'UserNotifications',
	setup() {
		const router = useRouter();
		const notifications = ref(null);
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const authToken = computed(() => store.state.authToken);

		onMounted(() => {
			getUserNotifications()
		})

		function getUserNotifications() {
			apiClient.get('/api/get/user-notifications',
				{
					headers: {
						'Authorization':  `Bearer ${store.state.authToken}`
					}
				}).then(response => {
					notifications.value = response.data
				}).catch(error => {
					console.log(error)
				});			
		}

		function viewProfile(username) {
			if(username != null && username != '' && username != undefined) {
				router.push('/profile/'+username)
			}
		}

		return { notifications, authUser, authToken, router, viewProfile }
	}
});
</script>